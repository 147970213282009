import React from "react";

import { Steps } from "antd";

const { Step } = Steps;

//

export const ThermoProtectStepper = (props) => {
  return (
    <>
      <div className="productStepper ccDesktopView">
        <Steps>
          <Step
            {...{
              status: "process",
              title: <h2>Ahorro Energético</h2>,
              description: <span>Ahorro energético promedio de un 30%</span>,
            }}
          />
          <Step
            {...{
              status: "process",
              title: <h2>Protección de la Edificación</h2>,
              description: (
                <span>
                  Impermeable y flexible, no se fractura protegiendo al edificio
                  de los agentes externos
                </span>
              ),
            }}
          />
          <Step
            {...{
              status: "process",
              title: <h2>Mejora estética</h2>,
              description: (
                <span>
                  Alta estabilidad cromática, resistente al paso del tiempo
                </span>
              ),
            }}
          />

          <Step
            {...{
              status: "process",
              title: <h2>Respetuoso con el Medio Ambiente</h2>,
              description: (
                <span>
                  No contiene disolventes ni materiales dañinos para el entorno
                </span>
              ),
            }}
          />
        </Steps>
      </div>

      <div className="productStepper ccMobileView">
        <Steps direction="vertical" labelPlacement="vertical">
          <Step
            {...{
              status: "process",
              title: <h2>Ahorro Energético</h2>,
              description: <span>Ahorro energético promedio de un 30%</span>,
            }}
          />
          <Step
            {...{
              status: "process",
              title: <h2>Protección de la Edificación</h2>,
              description: (
                <span>
                  Impermeable y flexible, no se fractura protegiendo al edificio
                  de los agentes externos
                </span>
              ),
            }}
          />
          <Step
            {...{
              status: "process",
              title: <h2>Mejora estética</h2>,
              description: (
                <span>
                  Alta estabilidad cromática, resistente al paso del tiempo
                </span>
              ),
            }}
          />

          <Step
            {...{
              status: "process",
              title: <h2>Respetuoso con el Medio Ambiente</h2>,
              description: (
                <span>
                  No contiene disolventes ni materiales dañinos para el entorno
                </span>
              ),
            }}
          />
        </Steps>
      </div>
    </>
  );
};

export default ThermoProtectStepper;
