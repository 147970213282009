import { Content } from "antd/lib/layout/layout";
import React from "react";

import { LayoutGeneral } from "src/layout/LayoutGeneral";
import { Divider } from "antd";

import ThermoProtectImgAndIntro from "src/components/products/imgAndIntro/ThermoProtect";
import ThermoProtectHowDoesItWork from "src/components/products/howDoesItWorkImgLeft/ThermoProtect";
import ThermoProtectListContentBlock from "src/components/products/listContentBlocks/ThermoProtect";
import ThermoProtectListPropertyCard from "src/components/products/listPropertyCards/ThermoProtect";
import ThermoProtectTableTechnicalData from "src/components/products/tableTechnicalData/ThermoProtect";

import ThermoProtectStepper from "src/components/products/steppers/ThermoProtect";

import GreenGuardCertificate from "src/components/products/blocks/GreenGuard";
import EnergySavingsHighligtedBlock from "src/components/products/blocks/highlighted/EnergySavings";
import ExteriorProductsMenu from "src/menus/product/Exterior";

import CarouselReferencias from "src/components/referencias/CarouselExterior";
import "src/components/products/style.scss";
import "src/components/products/thermoProtect/style.scss";
import CertificatesCarouselSecondary from "../../components/certificates/CarouselSecondary";

const ThermoProtect = (props) => (
  <LayoutGeneral
    {...{
      ...props,
      showPageTitle: false,
      productName: "ThermoProtect",
      pageTitle: "ClimateCoating ThermoProtect",
      pageName: "ThermoProtect",
      pageDescription:
        "ClimateCoating ThermoProtect: Membrana Termocerámica con redimiento endotérmico para exteriores",
    }}
  >
    <CarouselReferencias {...{ maxWidth: "100%", slidesToShow: 4 }} />

    <ExteriorProductsMenu {...{ activePage: "thermoProtect" }} />

    <Content className="product thermoProtect">
      <ThermoProtectImgAndIntro />

      <ThermoProtectStepper />

      <div
        style={{
          width: "90%",
          maxWidth: "1000px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "75px",
          marginBottom: "50px",
        }}
      >
        <video width="100%" controls>
          <source
            src="/videos/ClimateCoating-ThermoProtect-presentacion.mp4"
            type="video/mp4"
          />
          Your browser does not support HTML5 video.
        </video>
      </div>

      <ThermoProtectHowDoesItWork />

      <Divider className="dividerHorizontalCC" />

      <div className="clearBoth" />

      <EnergySavingsHighligtedBlock />

      <ThermoProtectListContentBlock />

      <ThermoProtectListPropertyCard />

      <GreenGuardCertificate />

      <ThermoProtectTableTechnicalData />

      <CertificatesCarouselSecondary
        {...{ maxWidth: "850px", slidesToShow: 5 }}
      />
    </Content>
  </LayoutGeneral>
);

export default ThermoProtect;
