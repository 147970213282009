import React from "react";

import thermoProtectImage from "src/components/products/images/thermoProtect.jpg";
import { Link } from "gatsby";
import { Row, Col, List } from "antd";
import CertificatesCarouselSecondary from "components/certificates/CarouselSecondary";
import {
  FilePptOutlined,
  FileProtectOutlined,
  SolutionOutlined,
  CheckOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "src/components/products/imgAndIntro/style.scss";

export const ThermoProtectImgAndIntro = (props) => {
  return (
    <div className="imgAndIntro">
      <div className="productImageLinksBlock">
        <img src={thermoProtectImage} />
        <Row className="productLinks">
          <Col span="8">
            <span className="linkIcon">
              <FilePptOutlined />
            </span>
            <span className="linkText">Presentación</span>

            <span id="productPresentation" className="downloadIcon">
              <a
                href="/thermoProtect/climateCoating-thermoProtect-presentacion.pdf"
                download
              >
                <DownloadOutlined />
              </a>
            </span>
          </Col>

          <Col span="8">
            <Link to="/fichas-tecnicas/thermoprotect/">
              <span className="linkIcon">
                <FileProtectOutlined />
              </span>
              <span className="linkText">Ficha Técnica</span>
            </Link>
            <span id="productFichaTecnica" className="downloadIcon">
              <a
                href="/thermoProtect/climateCoating-thermoProtect-ficha-tecnica.pdf"
                download
              >
                <DownloadOutlined />
              </a>
            </span>
          </Col>
          <Col span="8">
            <Link to="/fichas-de-aplicacion/thermoprotect/">
              <span className="linkIcon">
                <SolutionOutlined />
              </span>
              <span className="linkText">Ficha de Aplicación</span>
            </Link>

            <span id="productFichaAplicacion" className="downloadIcon">
              <a
                href="/thermoProtect/climateCoating-thermoProtect-ficha-de-aplicacion.pdf"
                download
              >
                <DownloadOutlined />
              </a>
            </span>
          </Col>
        </Row>
      </div>
      <h1>
        <span className="paddingRight10"> </span> ThermoProtect
      </h1>
      <strong>ClimateCoating ThermoProtect</strong> es un innovador
      revestimiento para fachadas basado en la tecnología de membrana
      termocerámica, que garantiza una protección duradera de las fachadas y un
      ahorro en el consumo de energía La innovadora fórmula con esferas
      cerámicas en combinación con sus materiales de alta calidad garantizan que
      tras aplicar el revestimiento se forme una membrana flexible con
      excelentes propiedades:
      <ul className="productFeatureList">
        <li>
          {" "}
          <CheckOutlined />
          Permeabilidad variable
        </li>
        <li>
          <CheckOutlined />
          Genera un ahorro de energía en calefacción y aire acondicionado
        </li>
        <li>
          <CheckOutlined />
          Proporciona condiciones de confort térmico
        </li>
        <li>
          <CheckOutlined />
          Permeabilidad variable al vapor de agua. Transpirable
        </li>
        <li>
          <CheckOutlined />
          Alta resistencia a influencias ambientales agresivas como la niebla
          tóxica (smog) y el ozono troposférico
        </li>
        <li>
          <CheckOutlined />
          Bajas emisiones y libre de disolventes
        </li>
        <li>
          <CheckOutlined />
          Alta resistencia a los rayos UV
        </li>
        <li>
          <CheckOutlined />
          Reflectante a la radiación solar
        </li>
        <li>
          <CheckOutlined />
          Muy alta resistencia de color
        </li>
      </ul>
    </div>
  );
};
export default ThermoProtectImgAndIntro;
