import React from "react";

export const EnergySavingsHighligtedBlock = (props) => (
  <div className="hightlighted" style={{ marginBottom: "50px" }}>
    <h3>
      Numerosas mediciones efectuadas en confirman que el efecto de
      ClimateCoating se traduce en una disminución de gastos de calefacción y
      aire acondicionado de hasta un 30%
    </h3>
  </div>
);

export default EnergySavingsHighligtedBlock;
