import React from "react";

import { MinusOutlined } from "@ant-design/icons";

import howDoesItWorkThermoProtect from "src/components/products/howDoesItWorkImgLeft/images/climatecoating-thermoprotect-funcionamiento.jpg";

import "src/components/products/howDoesItWorkImgLeft/style.scss";

export const ThermoProtectHowItWorks = (props) => (
  <>
    <div className="blockHowItWorks">
      <img src={howDoesItWorkThermoProtect} />
      <span className="blockText">
        <h2>¿CÓMO ACTÚA THERMOPROTECT?</h2>
        La tecnología ClimateCoating® permite a la membrana adaptar sus
        propiedades y comportamiento a las condiciones climatológicas exteriores
        y a la estación del año en la que nos encontremos, tal como hace la piel
        en el cuerpo humano.
        <ul className="explaList">
          <li>
            <MinusOutlined />
            Durante el invierno, la membrana, formada por microesferas cerámicas
            huecas, protege a la fachada de la entrada de agua y humedad
            exterior, mientras que absorbe la humedad del interior de los muros
            favoreciendo el aislamiento térmico.
          </li>
          <li>
            <MinusOutlined />
            En verano, las propiedades endotérmicas de ClimateCoating®
            ThermoProtect le permiten acumular humedad, tanto del interior de
            los muros por capilaridad como del ambiente exterior en su
            superficie por adsorción, generando un proceso de evaporación que,
            unido a su alta reflectancia de la radiación solar, reduce la
            temperatura de las fachadas y consigue un importante ahorro de
            energía en aire acondicionado.
          </li>
        </ul>
      </span>
    </div>
    <div className="clearBoth" />
  </>
);

export default ThermoProtectHowItWorks;
