import React from "react";

import { Link } from "gatsby";
import Slider from "react-slick";

import { PlusCircleOutlined, LikeOutlined } from "@ant-design/icons";

import laFlorida from "src/components/referencias/images/exterior/climateCoating-thermoProtect-la-florida-thumbnail.png";
import elParaiso from "src/components/referencias/images/exterior/climateCoating-thermoProtect-el-campanario-el-paraiso.jpg";
import laMoraleja from "src/components/referencias/images/exterior/climateCoating-thermoProtect-la-moraleja-madrid-thumbnail.jpg";
import sotogrande from "src/components/referencias/images/exterior/climateCoating-thermoProtect-sotogrande-cadiz-thumbnail.jpg";
import manuelTovar from "src/components/referencias/images/exterior/climateCoating-thermoProtect-coworking-manuel-tovar-thumbnail.jpg";

import { Card } from "antd";

const { Meta } = Card;

export const ReferenciasCarousel = ({
  float = "none",
  width = "1600px",
  maxWidth = "98%",
  maxHeight = "100px",
  marginLeft = "auto",
  marginRight = "auto",
  slidesToShow = 4,
  marginTop = "50px",
  marginBottom = "50px",
}) => {
  var settings = {
    dots: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow,
    rows: 1,
    infinite: false, //items.length > 3,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imgWith = 120;

  return (
    <div
      className="carouselReferencias"
      style={{
        width,
        float,
        maxWidth,
        marginBottom,
        maxHeight,
        marginRight,
        marginLeft,
        marginTop,
        marginBottom,
      }}
    >
      <Slider {...settings}>
        <div key="icono">
          <div className="refItem">
            <div className="referenceIcon thermoProtect colored">
              <LikeOutlined />
            </div>
            <div className="refText greyed icon">
              <span className="textRefTitle ">
                ClimateCoating para Exterior
              </span>
              <span className="textRefSubTitle">
                Selección de proyectos en España
              </span>
              <Link to={"/referencias/exterior/"}>
                <PlusCircleOutlined /> Ir a Referenicas
              </Link>
            </div>
            <div className="clearer height0"></div>
          </div>
        </div>

        <div key="laFlorida">
          <div className="refItem">
            <img style={{ width: imgWith }} alt="example" src={laFlorida} />
            <div className="refText">
              <span className="textRefTitle thermoProtect colored">
                Unifamiliar en la Florida
              </span>
              <span className="textRefSubTitle">Afueras de Madrid - A6</span>
              <Link to={"/referencias-exterior/casa-de-bertin-osborne/"}>
                <PlusCircleOutlined /> Más Info
              </Link>
            </div>
            <div className="clearer height0"></div>
          </div>
        </div>

        <div key="laMoraleja">
          <div className="refItem">
            <img style={{ width: imgWith }} alt="example" src={laMoraleja} />
            <div className="refText">
              <span className="textRefTitle thermoProtect colored">
                Unifamiliar en la Moraleja
              </span>
              <span className="textRefSubTitle">Afueras de Madrid - A1</span>
            </div>
            <div className="clearer height0"></div>
          </div>
        </div>
        <div key="manuelTovar">
          <div className="refItem">
            <img style={{ width: imgWith }} alt="example" src={manuelTovar} />
            <div className="refText">
              <span className="textRefTitle thermoProtect colored">
                Oficinas en Madrid
              </span>
              <span className="textRefSubTitle">C\ Manolo Tovar</span>
              <Link to={"/"}>
                <PlusCircleOutlined /> Más Info
              </Link>
            </div>
            <div className="clearer height0"></div>
          </div>
        </div>
        <div key="sotogrande">
          <div className="refItem">
            <img style={{ width: imgWith }} alt="example" src={sotogrande} />
            <div className="refText">
              <span className="textRefTitle thermoProtect colored">
                Unifamiliar en la Sotogrande
              </span>
              <span className="textRefSubTitle">Sogrande - Cádiz</span>
            </div>
            <div className="clearer height0"></div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default ReferenciasCarousel;
