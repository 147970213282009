import React from "react";

import { Link } from "gatsby";
import { Menu } from "antd";

export const ExteriorProductsMenu = ({ activePage }) => (
  <div id="ccProductWrapper" className="productMenuWrapper">
    <Menu
      onClick={() => console.log("whatever")}
      selectedKeys={[activePage]}
      mode="horizontal"
    >
      <Menu.Item key="thermoProtect" className="thermoProtect">
        <Link to="/productos/thermoprotect/"> ThermoProtect</Link>
      </Menu.Item>
    </Menu>
  </div>
);

export default ExteriorProductsMenu;
