import React from "react";

import { List } from "antd";

import "src/components/products/listContentBlocks/style.scss";

export const ThermoProtectListContentBlock = (props) => (
  <List
    {...{
      className: "listContentBlockWrapper",
      grid: {
        gutter: 20,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 2,
        xl: 2,
        xxl: 2,
      },
      dataSource: [
        {
          title: "MÁXIMA PROTECCIÓN Y DE LARGA DURACIÓN",
          content:
            "Una de las propiedades más importantes de ThermoProtect es la alta elasticidad del producto, que permite al revestimiento absorber fisuras de hasta 1mm. producidas por la expansión y contracción de los materiales de construcción de la envolvente de los edificios. Por otro lado, es impermeable al agua y tiene una alta resistencia a los rayos UV.",
        },
        {
          title: "MÁXIMA RESISTENCIA",
          content:
            "Es resistente al queroseno, al salitre y a la contaminación ambiental, así como repele la suciedad, y previene la aparición de condensaciones y hongos en las superficies. Su durabilidad y resistencia, unidas a la alta estabilidad del color, implica un ahorro económico muy importante en mantenimiento y renovación de acabado exterior de los edificios por motivos estéticos.",
        },
      ],
      renderItem: (item) => (
        <List.Item className="listContentBlock">
          <h3>{item.title}</h3>
          <p>{item.content}</p>
        </List.Item>
      ),
    }}
  />
);

export default ThermoProtectListContentBlock;
