import React from "react";

import certificadoGreenGuard from "src/components/certificates/images/normalSize/greenguard-certificate-climatecoating.jpg";

export const GreenGuardCertificate = (props) => (
  <>
    <div className="blockCertificate">
      <img src={certificadoGreenGuard} />
      <span className="blockText">
        Es un producto respetuoso con el medio ambiente, no produce ninguna
        contaminación del aire ambiente ya que no genera emisiones ayudando a
        disminuir el calentamiento global
      </span>
    </div>
    <div className="clearBoth" />
  </>
);

export default GreenGuardCertificate;
